<template>
<div>
  <l-map
    style="z-index:1!important; height:80vh; width: 100%"
    :zoom="mapZoom"
    :center="mapCenter"
    @update:zoom="zoomUpdated"
    @update:center="centerUpdated"
    @update:bounds="boundsUpdated"
    @ready="ready"
  >

    <l-tile-layer :url="url"    attribution='<a href="https://wikimediafoundation.org/wiki/Maps_Terms_of_Use">Wikimedia</a>'></l-tile-layer>
    <l-control position="bottomleft" >

      <v-layout wrap class="">
        <v-checkbox label="Search as I move the map" background-color="rgba(255,255,255,.75)" v-model="searching"></v-checkbox>
      </v-layout>
    </l-control>
    <l-feature-group layer-type="overlay" :visible="cZoom>=13" >
      <l-marker  :options="{zIndexOffset:-900}" :icon="mrtIcon" v-for="(el,index) in mrt" :key="index"  :lat-lng="[el.lat,el.lng]">
        <l-popup style="width: 180px!important;">
          <v-layout wrap justify-center>
            <v-flex class="text-xs-center pa-2" xs12>
              {{el.name}} MRT Station
            </v-flex>
          </v-layout>
        </l-popup>

      </l-marker>
      <l-marker  :options="{zIndexOffset:-900}" :icon="schoolIcon" v-for="(el) in schools" :key="el.name"  :lat-lng="[el.lat,el.lng]">
        <l-popup style="width: 180px!important;">
          <v-layout wrap justify-center>
            <v-flex class="text-xs-center pa-2" xs12>
              {{el.name}}
            </v-flex>
          </v-layout>
        </l-popup>

      </l-marker>

    </l-feature-group>
    <l-marker :icon="icon" :options="{zIndexOffset:900}" :lat-lng="[center.lat,center.lng]">
      <!-- <l-icon icon-url="'https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,w_40/v1537938360/clusterIcons/mapsIcon.png'"></l-icon> -->
      <l-popup style="width: 180px!important;">
        <v-layout wrap justify-center>
          <img  :src="place.url"  width="150" alt="sunset on Taipei 101 view from Elephant mountain">
          <v-flex class="text-xs-center pa-2" xs12>
            <a class="pt-2">{{place.title}}</a>
          </v-flex>
          <v-flex xs12>
            {{place.info}}
          </v-flex>
          <br>
        </v-layout>
      </l-popup>
    </l-marker>
    <l-cluster :options="options">

      <div class="" v-for="i in 50" :key="roomsOnMap[i-1]['.key']" v-if="roomsOnMap[i-1]!=undefined">
        <l-marker   :lat-lng="[roomsOnMap[i-1].general.location.lat,roomsOnMap[i-1].general.location.lng]">
          <l-icon :icon-anchor="[35,15]" >
            <span class="price-marker" v-if="roomsOnMap[i-1].type!='roomApt' && roomsOnMap[i-1].hasOwnProperty('billing')">{{ roomsOnMap[i-1].billing.price }} TW$</span>
            <span class="price-marker" v-else>{{roomsOnMap[i-1].featureList.numberOfBedrooms}} Rooms</span>
          </l-icon>
          <l-popup style="width: 180px!important;">
            <v-layout wrap justify-center>
              <v-flex xs12 class="text-xs-center">
                <img v-if="roomsOnMap[i-1].hasOwnProperty('visual') && roomsOnMap[i-1].visual.hasOwnProperty('photos')" :src="'https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,w_600/v1544150358/'+roomsOnMap[i-1].visual.photos[0].id"  width="150" alt=""/>
              </v-flex>
              <v-flex xs12 class="text-xs-center">
                <a :href="roomsOnMap[i-1].url" target="_blank">{{roomsOnMap[i-1].general.title}}</a>

              </v-flex>
              <v-flex xs12 class="text-xs-center" v-if="roomsOnMap[i-1].type!='roomApt' && roomsOnMap[i-1].hasOwnProperty('billing')">
                <span v-if="roomsOnMap[i-1].type=='wholeApt'">{{roomsOnMap[i-1].featureList.numberOfBedrooms}} Rooms -</span> {{roomsOnMap[i-1].billing.price}} TW$

              </v-flex>
              <v-flex xs12 class="text-xs-center" v-else>
                {{roomsOnMap[i-1].featureList.numberOfBedrooms}} Rooms

              </v-flex>
            </v-layout>
          </l-popup>
        </l-marker>
      </div>
    </l-cluster>
    <l-marker :options="{zIndexOffset:999}" :lat-lng="[hoverRoom.general.location.lat,hoverRoom.general.location.lng]" v-if="hoverRoom!=null">
      <!-- <l-icon icon-url="'https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,w_40/v1537938360/clusterIcons/mapsIcon.png'"></l-icon> -->
        <l-icon  :icon-anchor="[41,15]" >
          <span class="hover-marker" v-if="hoverRoom.type!='roomApt' && hoverRoom.hasOwnProperty('billing')">{{ hoverRoom.billing.price }} TW$</span>
          <span class="hover-marker" v-else>{{ hoverRoom.featureList.numberOfBedrooms }} Rooms</span>
        </l-icon>
        <l-popup style="width: 180px!important;">
          <v-layout wrap justify-center>
            <v-flex xs12 class="text-xs-center">
              <img v-if="hoverRoom.hasOwnProperty('visual') && hoverRoom.visual.hasOwnProperty('photos')" :src="'https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,w_600/v1544150358/'+hoverRoom.visual.photos[0].id"  width="150" alt=""/>
            </v-flex>
            <v-flex xs12 class="text-xs-center">
              <a :href="hoverRoom.url" target="_blank">{{hoverRoom.general.title}}</a>

            </v-flex>
            <v-flex xs12 class="text-xs-center" v-if="hoverRoom.type!='roomApt' && hoverRoom.hasOwnProperty('billing')">
              {{hoverRoom.billing.price}} TW$
            </v-flex>
            <v-flex xs12 class="text-xs-center" v-else>
              {{hoverRoom.featureList.numberOfBedrooms}} Rooms

            </v-flex>
          </v-layout>
        </l-popup>
    </l-marker>
  </l-map>
</div>
</template>

<script>
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import inside from 'point-in-polygon'
import firebase from '../../FirebaseApp'
export default {
  props:['zoom','hoverRoom','rooms','center', 'place', 'page', 'ordered'],
  data: () =>({
    attributions:['MapTiler',"OpenStreetMap contributors"],
    url: 'https://a.tile.openstreetmap.de/{z}/{x}/{y}.png',
    bounds: {
       "_southWest": { "lat": 24.958670130576788, "lng": 121.46141052246095 }, "_northEast": { "lat": 25.114201938326083, "lng": 121.63307189941408 }
    },
    mapsIcon:'https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,w_40/v1537938360/clusterIcons/mapsIcon.png',
    icon: L.icon({
      iconUrl: 'https://res.cloudinary.com/my-room-abroad/image/upload/v1568260858/clusterIcons/blue_logo.png',
      iconSize: [40, 41],

    }),
    mrtIcon: L.icon({
      iconUrl: 'https://res.cloudinary.com/my-room-abroad/image/upload/v1568709209/clusterIcons/metro200.svg',
      iconSize: [8, 8],

    }),
    schoolIcon: L.icon({
      iconUrl: 'https://res.cloudinary.com/my-room-abroad/image/upload/v1568716400/clusterIcons/school4.png',
      iconSize: [15, 15],

    }),
    cZoom:14,
    mrt:[],
    schools:[],
    searching:true,
  }),
  firebase() {
    return {
      locations: {
        source:firebase.database().ref('locations'),
        readyCallback:function(){
          for (var i = 0; i < this.locations.length; i++) {

            if (this.locations[i].icon== 'directions_transit' && !this.locations[i].name.match(/[\u3400-\u9FBF]/) ) {
              this.mrt.push(this.locations[i])
            } else if ( this.locations[i].icon== 'school' && !this.locations[i].name.match(/[\u3400-\u9FBF]/) && this.schools.findIndex(x=>(x.lat==this.locations[i].lat && x.lng==this.locations[i].lng))==-1 ) {
              this.schools.push(this.locations[i])
            }
          }
        }
      },
    }
  },
  methods: {
    ready(payload){
      // console.log(' ready: ',payload.getBounds());
      this.bounds=payload.getBounds()
    },
    zoomUpdated (zoom) {
      this.cZoom=zoom
    },
    centerUpdated (center) {

    },

    boundsUpdated (bounds) {
      if (this.searching!=false){
        this.bounds=bounds
        this.$emit('resetPage')
      } else {
        console.log(this.searching)
      }
    },
    findVisible(rooms, bounds){
      let t=[]
      let p1=[]
      let p2=[]
      let p3=[]
      let p4=[]
      if (bounds.hasOwnProperty('_southWest') && bounds.hasOwnProperty('_northEast')){
        p1=[bounds._southWest.lat,bounds._southWest.lng]
        p2=[bounds._southWest.lat,bounds._northEast.lng]
        p3=[bounds._northEast.lat,bounds._northEast.lng]
        p4=[bounds._northEast.lat,bounds._southWest.lng]
      }
      // console.log();
      for (var i = 0; i < rooms.length; i++) {
        if (rooms[i].hasOwnProperty('general')&&rooms[i].general.hasOwnProperty('location')){
          if (inside([rooms[i].general.location.lat,rooms[i].general.location.lng],[p1,p2,p3,p4])){
            t.push(rooms[i])
          }

        }
      }
      // this.$emit('changeVisibleRooms',t)
      return(t)
    },
    getNextFiftyAndRemaining(visible){
        let ne=[]
        let se=[]
        let sw=[]
        let nw=[]
        let nec=[[this.bounds._northEast.lat,this.bounds._northEast.lng],[this.bounds._northEast.lat,this.center.lng],[this.center.lat,this.center.lng],[this.center.lat,this.bounds._northEast.lng]]
        let sec=[[this.bounds._southWest.lat,this.bounds._northEast.lng],[this.bounds._southWest.lat,this.center.lng],[this.center.lat,this.center.lng],[this.center.lat,this.bounds._northEast.lng]]
        let swc=[[this.bounds._southWest.lat,this.bounds._southWest.lng],[this.bounds._southWest.lat,this.center.lng],[this.center.lat,this.center.lng],[this.center.lat,this.bounds._southWest.lng]]
        let nwc=[[this.bounds._northEast.lat,this.bounds._southWest.lng],[this.bounds._northEast.lat,this.center.lng],[this.center.lat,this.center.lng],[this.center.lat,this.bounds._southWest.lng]]
        let i=0
        let other=[]
        let current=[]

        if (this.ordered!=true){

          while(ne.length+se.length+sw.length+nw.length<30 && i<visible.length){
            if (inside([visible[i].general.location.lat,visible[i].general.location.lng],nec) && ne.length<8){
              ne.push(visible[i])
              current.push(visible[i])
            } else if (inside([visible[i].general.location.lat,visible[i].general.location.lng],sec) && se.length<8) {
              se.push(visible[i])
              current.push(visible[i])
            } else if (inside([visible[i].general.location.lat,visible[i].general.location.lng],swc) && sw.length<8) {
              sw.push(visible[i])
              current.push(visible[i])
            } else if (inside([visible[i].general.location.lat,visible[i].general.location.lng],nwc) && nw.length<8) {
              nw.push(visible[i])
              current.push(visible[i])
            } else {
              other.push(visible[i])
            }
            i++
            // console.log(i);
          }
          for (var j=i ; j < visible.length; j++) {
            other.push(visible[j])
          }
          // console.log('FROM WHILE 2:');
          while (ne.length+se.length+sw.length+nw.length<30 && other.length>0){
            // console.log(other[0], visible[i]);
            ne.push(other[0])
            current.push(other[0])
            other.shift()
          }

      } else {
        // console.log('FROM ELSE:');
        while (ne.length<30 && i<visible.length){

            // console.log(visible[i]);
            ne.push(visible[i])
            current.push(visible[i])
            i++
        }
        for (var j=i ; j < visible.length; j++) {
          other.push(visible[j])

        }
      }
      let all={
        current:current,
        other:other
      }
      return all
    }

  },
  computed:{
    mapZoom(){
      return this.zoom
    },
    mapCenter(){
      return this.center
    },
    options(){
      return {
        // 'disable-clustering-at-zoom': 5,
        // disableClusteringAtZoom:5,
        // 'max-cluster-radius':1,

        maxClusterRadius:3
      }
    },
    visible(){
      return this.findVisible(this.rooms, this.bounds)
    },
    roomsOnMap(){

      let remaining=this.visible
      // console.log(this.visible, 'THE VISIBLE');
      let t={current:[]}
      // let other=[]
      let current=[]
      let past=[]

      for (var i = 0; i < this.page; i++) {
        past = t.current.concat(past)
        t=this.getNextFiftyAndRemaining(remaining)
        remaining=t.other
        current=t.current
        // other=t.other
      }
      // console.log([...ne,...se,...sw,...nw])
      let all={current:t.current, other:[...past,...remaining]}
      console.log(t.current);
      this.$emit('changeVisibleRooms',all)

      return t.current
    },

  },
  created(){
    // console.log(this.$refs);
  },
  components: {
    'l-cluster': Vue2LeafletMarkerCluster
  },
}
</script>
<style lang="scss">
.marker-cluster-small{
  background-color: rgba(0, 191, 255,.4)!important;
  div {
      background-color: rgba(0, 191, 255,.5)!important
  }
  color: white;
}
.marker-cluster-medium{
  background-color: rgba(0, 191, 255,.4)!important;
  div {
      background-color: rgba(0, 191, 255,.5)!important
  }
  color: white;
}

</style>
<style lang="scss" scoped>
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
.price-marker{
  background-color:#fff;
  padding: .5em;
  color: rgba(0,0,0,.75);
  border-radius: 2px;
  border: solid thin #ccc;
  white-space: nowrap;
  display: inline-block;
}
.hover{
  z-index:999!important;
}
.hover-marker{
  padding: .6em;
  font-size: 1.2em;
  font-weight: bold;
  background-color:#fff;
  color: rgba(0,0,0,.75);
  border-radius: 2px;
  border: solid thin #ccc;
  white-space: nowrap;
  display: inline-block;
  z-index: 999!important;
}
.map-overlay{
  background-color:rgba(255,255,255,1)
}
</style>
